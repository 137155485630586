const classes = {
  root: {
    display: 'flex',
    height: '100%',
  },
  toolbar: {
    height: '70px',
  },
  contentMobile: {
    flexGrow: '1',
    width: '100%',
  },
  content: {
    flexGrow: '1',
    padding: '2em',
    width: '100%',
  },
};

export default classes;
