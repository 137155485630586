import React from 'react';
import ReactDom from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import './index.css';
import ToggleColorModeProvide from './utils/ToogleColorMode';
import App from './components/App';
import store from './app/store';

const root = ReactDom.createRoot(document.getElementById('root'));

root.render(
  <ReduxProvider store={store}>
    <ToggleColorModeProvide>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ToggleColorModeProvide>
  </ReduxProvider>
);
