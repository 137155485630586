const classes = {
  containerSpaceAround: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '10px 0 !important',
  },
  bannerContainer: {},
  banner: {
    borderRadius: '20px',
    boxShadow: '0.5em 1em 1em rgb(64,64,70,.097)',
    width: '95%',
  },
  bannerMedium: {
    margin: '0 auto!important',
    borderRadius: '15px',
    boxShadow: '0.5em 1em 1em rgb(64,64,70,.57)',
    width: '100%',
  },
  bannerMobile: {
    margin: '0 auto!important',
    boxShadow: '0 .2em .1em rgb(64,64,70,.57)',
    marginTop: '10px',
    width: '100%',
  },
  btnContainer: {
    display: 'flex !important',
    justifyContent: 'space-between',
    width: '100%',
  },
  racepackImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

export default classes;
