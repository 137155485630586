import { Box, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import classes from './style';
import Image from 'mui-image';

const RacepacksFragement = ({ racepacks }) => {
  const [featuredRcp, setFeaturedRcp] = useState(racepacks[0]?.image);

  const handleRcpImage = (rcp) => {
    setFeaturedRcp(rcp.image);
  };

  // useEffect(() => {
  //   console.log('here', racepacks);
  // }, [racepacks]);

  if (!racepacks || racepacks.length === 0)
    return (
      <Box
        display={'flex'}
        marginTop={'2rem'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{ width: '80%' }}
          variant="h4"
          align="center"
          color={'GrayText'}
        >
          Contact Event organizer for more information.
        </Typography>
      </Box>
    );

  return (
    <div style={{ marginTop: '20px' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            maxWidth: '350px',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <Image
            src={featuredRcp}
            alt="featured_rcp"
            style={classes.racepackImage}
            showLoading
          />
        </div>
      </div>
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        {racepacks?.map((racepack, idx) => (
          <div
            style={{
              marginRight: '10px',
              borderRadius: '8px',
              overflow: 'hidden',
              width: '64px',
              height: '64px',
              cursor: 'pointer',
            }}
            onClick={() => handleRcpImage(racepack)}
          >
            <Image
              key={idx}
              src={racepack?.image}
              alt={racepack?.name}
              style={classes.racepackImage}
              showLoading
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RacepacksFragement;
