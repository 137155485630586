import { Box, Typography } from '@mui/material';

const EventInfoFragment = ({ label, body, isMobile }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} marginTop={'1rem'}>
      <Typography variant={isMobile ? 'h6' : 'h5'}>{label}</Typography>
      <Typography
        variant={isMobile ? 'body2' : 'h6'}
        sx={{ whiteSpace: 'pre-wrap' }}
      >
        {body}
      </Typography>
    </Box>
  );
};

export default EventInfoFragment;
