export const toCurrencyForm = (text) => {
  let string;
  if (typeof text === 'number') string = text.toString();
  else string = text;

  if (!string) return '';
  // console.log(string);
  const stringN = string.length;
  // console.log(stringN);
  if (stringN < 4) return stringN;

  const ceilDivBy3 = Math.ceil(stringN / 3);
  // console.log(ceilDivBy3);
  let toNumberArray = [];
  let startIdx = stringN - 3;

  for (let i = 1; i <= ceilDivBy3; i++) {
    if (startIdx > 0) {
      toNumberArray[ceilDivBy3 - i] = string.substr(startIdx, 3);
      string = string.slice(0, startIdx);
      // console.log('if ', string);
    } else {
      // console.log('else ', string);
      toNumberArray[ceilDivBy3 - i] = string;
    }

    startIdx -= 3;
  }
  return toNumberArray.join('.');
};
