import {
  AccessTime,
  CalendarToday,
  LocationOn,
  Timer,
  TimerOff,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  toDateRange,
  toReadableDateTimeString,
  toTimeRange,
} from '../../utils/dateFormatter';

const RaceOrRacepakInfo = ({
  label,
  location,
  start,
  end,
  isMobile,
  isRacepack,
}) => {
  return (
    <>
      <Typography
        variant={!isMobile ? 'h5' : 'h6'}
        fontWeight={'600'}
        style={{ marginTop: '1rem' }}
      >
        {label}
      </Typography>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <LocationOn fontSize="small" style={{ marginRight: '6px' }} />
        <Typography
          variant={!isMobile ? 'h6' : 'body2'}
          style={{ justifyContent: 'center' }}
        >
          {location}
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <CalendarToday fontSize="small" style={{ marginRight: '6px' }} />

        <Typography
          variant={!isMobile ? 'h6' : 'body2'}
          align="center"
          style={{ justifyContent: 'center', letterSpacing: '1px' }}
        >
          {toDateRange(start, end)}
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <AccessTime fontSize="small" style={{ marginRight: '6px' }} />

        <Typography
          variant={!isMobile ? 'h6' : 'body2'}
          align="center"
          style={{ justifyContent: 'center', letterSpacing: '1px' }}
        >
          {toTimeRange(start, end)}
        </Typography>
      </Box>
    </>
  );
};

export default RaceOrRacepakInfo;
