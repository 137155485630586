import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';

const CountdownTimer = ({ openRegDate }) => {
  const Ref = useRef();
  const [state, setState] = useState({
    timer: '00:00:00',
  });
  const [isOpen, setIsOpen] = useState();

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(total / 1000 / 60 / 60);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setState({
        timer:
          (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds),
      });
    } else {
      clearInterval(Ref.current);
      setIsOpen(true);
      setState({ timer: 'Reload the page to join the event!' });
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setState({ timer: '00:00:00' });

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you extend to add more time
    const openReg = dayjs(openRegDate);
    const today = dayjs(deadline);
    const diffInSeconds = openReg.diff(today, 'seconds');

    // const diffInSeconds = openReg.getTime() - deadline.getTime();
    console.log(diffInSeconds);

    deadline.setSeconds(deadline.getSeconds() + diffInSeconds);
    return deadline;
  };

  //   const onClickReset = () => {
  //     clearTimer(getDeadTime());
  //   };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  return (
    <Box textAlign={'center'}>
      <Typography variant="h5" color={'GrayText'}>
        Registration Open Countdown
      </Typography>
      <Typography variant={isOpen ? 'h5' : 'h2'}>{state.timer}</Typography>
    </Box>
  );
};

export default CountdownTimer;
