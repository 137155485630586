import { AppBar, IconButton, Toolbar } from '@mui/material';
import classes from './style';

const Navbar = () => {
  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: 'white' }}>
        <Toolbar style={classes.toolbar}>
          <IconButton>
            <img
              src={require('../../assets/irace_web_logo.png')}
              alt={'irace'}
              style={{ height: '48px', width: '110px' }}
            />
          </IconButton>
          {/* <IconButton
            color="inherit"
            edge="start"
            style={{ outline: 'none' }}
            onClick={colorMode.toggleColorMode}
            sx={{ ml: 1 }}
          >
            {theme.palette.mode === 'dark' ? (
              <Brightness7 />
            ) : (
              <Brightness4 color="primary" />
            )}
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
