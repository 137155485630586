import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const iraceDbApi = createApi({
  reducerPath: 'iracedbApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}/api`,
  }),
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: () => `events/offlanes/web`,
    }),

    //* Get Event Information
    getEventInformation: builder.query({
      query: (id) => `events/offlanes/web/${id}`,
    }),

    //* Get order info
    getOrderInfo: builder.query({
      query: (id) => `events/offline/order?order_id=${id}&get_field=order_type`,
    }),

    //* Check coupon
    getCouponData: builder.query({
      query: ({ eventId, code }) => `events/offline/coupon/${eventId}/${code}`,
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventInformationQuery,
  useGetOrderInfoQuery,
  useGetCouponDataQuery,
} = iraceDbApi;
