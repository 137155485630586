const classes = {
  event: {},
  bannerMobile: {
    width: '100%',
    marginTop: '10px',
    objectFit: 'contain',
  },
  banner: {
    width: '100%',
    objectFit: 'contain',
  },
  link: {
    textDecoration: 'none',
  },
};

export default classes;
