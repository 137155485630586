import { MenuItem, TextField, useMediaQuery } from '@mui/material';

const FormTextField = ({
  index,
  i,
  required,
  disabled,
  type,
  selectOpts,
  label,
  errorMessage,
  value,
  onChange,
  readOnly,
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleOnKeyDown = (e) => {
    console.log(e.key);
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  if (type === 'select')
    return (
      <TextField
        id={`textfield-${i}${index}`}
        fullWidth
        color={'divider'}
        required={required}
        disabled={disabled}
        select
        error={!!errorMessage}
        label={label}
        helperText={errorMessage}
        value={value}
        onChange={onChange}
        style={{ marginBottom: isMobile ? '10px' : '14px' }}
      >
        {selectOpts?.map((opt, idx) => (
          <MenuItem
            key={idx}
            value={opt.name}
            disabled={opt.clickable !== undefined && opt.clickable === false}
          >
            {opt.name} {opt.extra ? ` ${opt.extra}` : ''}
          </MenuItem>
        ))}
      </TextField>
    );

  if (type === 'date' || type === 'time')
    return (
      <TextField
        id={`textfield-${i}${index}`}
        fullWidth
        color={'divider'}
        required={required}
        disabled={disabled}
        type={type}
        label={label}
        error={!!errorMessage}
        InputLabelProps={{ shrink: true }}
        helperText={errorMessage}
        value={value}
        onChange={onChange}
        style={{ marginBottom: isMobile ? '10px' : '14px' }}
      />
    );

  return (
    <TextField
      id={`textfield-${i}${index}-error`}
      fullWidth
      color={'divider'}
      required={required}
      disabled={disabled}
      error={!!errorMessage}
      type={type}
      label={label}
      helperText={errorMessage}
      value={value}
      InputProps={{ readOnly }}
      onChange={onChange}
      onKeyDown={handleOnKeyDown}
      style={{ marginBottom: isMobile ? '10px' : '14px' }}
    />
  );
};

export default FormTextField;
