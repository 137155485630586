import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useGetEventsQuery } from '../../services/iracedb';
import { IraceEventList } from '..';
import { Error } from '@mui/icons-material';

// const eventsDummy = [
//   {
//     eventId: 39,
//     sport: 'Run',
//     name: 'PORT99 FUN RUN',
//     startRegistrationDate: '2023-12-16T17:00:00.000Z',
//     endRegistrationDate: '2024-01-25T16:59:59.000Z',
//     banner:
//       'https://aa44-2001-448a-1150-621d-9c5b-ddb9-5af1-216a.ngrok-free.app/assets/banner/port99_5.jpg',
//     participant: 314,
//     sports: [
//       { name: 'Run' },
//       { name: 'Ride' },
//       { name: 'Skating' },
//       { name: 'Swimming' },
//       { name: 'Run' },
//       { name: 'Ride' },
//       { name: 'Skating' },
//       { name: 'Swimming' },
//     ],
//   },
//   {
//     eventId: 39,
//     sport: 'Run',
//     name: 'PORT99 FUN RUN',
//     startRegistrationDate: '2023-12-16T17:00:00.000Z',
//     endRegistrationDate: '2024-01-25T16:59:59.000Z',
//     banner:
//       'https://aa44-2001-448a-1150-621d-9c5b-ddb9-5af1-216a.ngrok-free.app/assets/banner/port99_5.jpg',
//     participant: 314,
//     sports: [
//       { name: 'Run' },
//       { name: 'Ride' },
//       { name: 'Skating' },
//       { name: 'Swimming' },
//       { name: 'Run' },
//       { name: 'Ride' },
//       { name: 'Skating' },
//       { name: 'Swimming' },
//     ],
//   },
//   {
//     eventId: 39,
//     sport: 'Run',
//     name: 'PORT99 FUN RUN',
//     startRegistrationDate: '2023-12-16T17:00:00.000Z',
//     endRegistrationDate: '2024-01-25T16:59:59.000Z',
//     banner:
//       'https://aa44-2001-448a-1150-621d-9c5b-ddb9-5af1-216a.ngrok-free.app/assets/banner/port99_5.jpg',
//     participant: 314,
//     sports: [{ name: 'Run' }],
//   },
//   {
//     eventId: 39,
//     sport: 'Run',
//     name: 'PORT99 FUN RUN',
//     startRegistrationDate: '2023-12-16T17:00:00.000Z',
//     endRegistrationDate: '2024-01-25T16:59:59.000Z',
//     banner:
//       'https://aa44-2001-448a-1150-621d-9c5b-ddb9-5af1-216a.ngrok-free.app/assets/banner/port99_5.jpg',
//     participant: 314,
//     sports: [{ name: 'Run' }],
//   },
//   {
//     eventId: 39,
//     sport: 'Run',
//     name: 'PORT99 FUN RUN',
//     startRegistrationDate: '2023-12-16T17:00:00.000Z',
//     endRegistrationDate: '2024-01-25T16:59:59.000Z',
//     banner:
//       'https://aa44-2001-448a-1150-621d-9c5b-ddb9-5af1-216a.ngrok-free.app/assets/banner/port99_5.jpg',
//     participant: 314,
//     sports: [{ name: 'Run' }],
//   },
// ];

const IraceEvents = () => {
  // console.log('irace events');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { data, isFetching, error } = useGetEventsQuery();

  // console.log('HERE ', process.env.REACT_APP_BASE_API_URL);
  if (error)
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        marginTop={'2rem'}
        flexDirection={'column'}
      >
        <Error sx={{ fontSize: '64px', color: '#afafaf' }} />
        <Typography sx={{ color: '#afafaf' }} variant="h3">
          {error.error.split(':')[1].toUpperCase()}
        </Typography>
      </Box>
    );

  if (isFetching)
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        marginTop={'2rem'}
      >
        <CircularProgress size={'4rem'} />
      </Box>
    );

  // console.log('here', data);

  return (
    <div style={isMobile ? { padding: '2em 1em' } : {}}>
      <IraceEventList events={data} />
    </div>
  );
};

export default IraceEvents;
