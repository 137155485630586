import { Grid } from '@mui/material';
import { IraceEvent } from '..';
import classes from './styles';

const IraceEventList = ({ events }) => {
  // console.log('irace event list');
  return (
    <Grid
      container
      style={classes.iraceEventListContainer}
      sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}
    >
      {events.map((ev, idx) => (
        <IraceEvent event={ev} i={idx} />
      ))}
    </Grid>
  );
};

export default IraceEventList;
