const classes = {
  toolbar: {
    height: '80px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuBtn: {
    outline: 'none',
  },
};

export default classes;
