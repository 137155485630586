import { EventAvailable, EventBusy, People } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Grow,
  Skeleton,
  Typography,
  chipClasses,
} from '@mui/material';
import classes from './style';
import { Link } from 'react-router-dom';
// import { encode } from 'js-base64';
import { toReadableDateTimeString } from '../../utils/dateFormatter';
import Image from 'mui-image';
import { generate } from '../../utils/generateToken';

const IraceEvent = ({ event, i }) => {
  // console.log('irace event ', generate(45));

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={classes.event}>
      <Grow key={i} in timeout={(i + 1) * 250}>
        <Link style={classes.link} to={`/event/${generate(event?.eventId)}`}>
          <Card
            style={{
              margin: '5px',
              borderRadius: '20px',
              marginBottom: '10px',
            }}
            sx={{ width: { xs: '98%', sm: '95%' } }}
          >
            <Image
              src={event?.banner}
              alt={event?.name}
              style={classes.banner}
              duration={500}
              showLoading={
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  sx={{ height: { xs: '12em', sm: '8em', md: '9em' } }}
                />
              }
            />

            <CardContent sx={{ paddingTop: '0', marginBottom: '-10px' }}>
              <Typography variant={'h5'} gutterBottom>
                {event.name}
              </Typography>
              <Typography gutterBottom>
                <Chip
                  icon={<EventAvailable />}
                  label={toReadableDateTimeString(event.startRegistrationDate)}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    paddingInline: '6px',
                    letterSpacing: '1px',
                  }}
                />
              </Typography>

              <Typography gutterBottom>
                <Chip
                  icon={<EventBusy />}
                  label={toReadableDateTimeString(event?.endRegistrationDate)}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    paddingInline: '6px',
                    letterSpacing: '1px',
                  }}
                />
              </Typography>
              <Box
                display={'flex'}
                flexDirection={'row'}
                flexWrap={'wrap'}
                marginTop={'20px'}
              >
                {event?.sports?.map((sport, idx) => (
                  <Chip
                    key={idx}
                    label={sport.name}
                    style={{
                      marginRight: '10px',
                      paddingInline: '4px',
                      color: '#830000',
                      backgroundColor: '#ffd3d3',
                      marginBottom: '6px',
                    }}
                    size="small"
                  />
                ))}
                <Chip
                  label={event.participant}
                  style={{
                    paddingInline: '4px',
                    color: '#830000',
                    backgroundColor: '#ffd3d3',
                  }}
                  icon={<People />}
                  size="small"
                  sx={{
                    [`& .${chipClasses.icon}`]: {
                      color: '#830000',
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Link>
      </Grow>
    </Grid>
  );
};

export default IraceEvent;
