import dayjs from 'dayjs';

export const toReadableDateTimeString = (date) => {
  if (!date) return;
  return dayjs(date).format('DD/MM/YYYY HH:mm');
};

export const toReadableDateString = (date) => {
  if (!date) return;
  return dayjs(date).format('DD/MM/YYYY');
};

export const toDateRange = (start, end) => {
  if (!start || !end) return 'TBA';
  const startDate = dayjs(start).format('DD-MMMM-YYYY').split('-');
  const endDate = dayjs(end).format('DD-MMMM-YYYY').split('-');
  const yearNow = dayjs().get('year');

  let result = `${startDate[0]}-${endDate[0]} ${startDate[1]}`;

  if (startDate[1] !== endDate[1]) {
    result = `${startDate[0]} ${startDate[1]}-${endDate[0]} ${endDate[1]}`;
  }

  if (
    startDate[2] !== endDate[2] ||
    startDate[2] !== yearNow.toString() ||
    endDate[2] !== yearNow.toString()
  ) {
    result = `${startDate[0]} ${startDate[1]} ${startDate[2]}-${endDate[0]} ${endDate[1]} ${endDate[2]}`;
  }

  if (
    startDate[0] === endDate[0] &&
    startDate[1] === endDate[1] &&
    startDate[2] === endDate[2]
  ) {
    result = `${startDate[0]} ${startDate[1]} ${startDate[2]}`;
  }
  return result;
};

export const toTimeRange = (start, end) => {
  if (!start || !end) return 'TBA';
  const startTime = dayjs(start).format('HH:mm');
  const endTime = dayjs(end).format('HH:mm');

  return `${startTime}-${endTime}`;
};

export const toSQLFormat = (date) => {
  if (!date) return;
  return dayjs(date).format('MM/DD/YYYY');
};
