import { decode, encode } from 'js-base64';

export const generate = (string) => {
  const base64 = encode(string);
  const token = encode(`${base64}+${process.env.REACT_APP_BASE64_KEY}`);

  return token;
};

export const read = (token) => {
  const tokenDecode = decode(token);
  const string = decode(tokenDecode.split('+')[0]);

  return string;
};
