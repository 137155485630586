import { Box, Typography } from '@mui/material';

const CustomTabPanel = ({ children, value, index, ...other }) => {
  // console.log(`${value} - index : ${index}`);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`basic-tabpanel-${index}`}
      aria-labelledby={`basic-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
export const a11lyProps = (index) => {
  return {
    id: `basic-tab-${index}`,
    'aria-controls': `basic-tabpanel-${index}`,
  };
};

export default CustomTabPanel;
